
.leaflet-bottom.leaflet-right {
    display: none !important;
}

:root {
    --object-item-height: 100px;
    --main: #999;
    --main-active: #ccc;
    --box-space: 15px;
}

.top5 {margin-top: 5px}
.top10 {margin-top: 10px}
.top15 {margin-top: 15px}
.top20 {margin-top: 20px}
.top30 {margin-top: 30px}
.top40 {margin-top: 40px}
.top50 {margin-top: 50px}

.pointer {
    cursor: pointer;
}
body {
    margin: 0;
    padding: 0;
    background: #ffffff;
    font-family: Roboto, sans-serif;
    overflow-x: hidden;
}

.center {text-align: center}

.flex {
    display: flex
}

.objects-map-container {
    width: 100%;
    height: 600px !important;
    background: #E0DFDF;
}



.objects-map-container .leaflet-container {
    width: 100% !important;
    height: 100% !important;
}

.containers {
    display: block;
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

.inner-containers {
    width: calc(100% + var(--box-space));
}

.containers .title {
    text-align: center;
    text-transform: uppercase;
    line-height: 50px;
}

.button {
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ccc;
    background: #ffffff;
    color: #000;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
}

.button:hover{background: #f1f1f1}

.default-btn {
    background: var(--main);
    color: #ffffff;
    border: 1px solid var(--main-active);
    text-decoration: none;
    padding: 2px 6px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    border-radius: 3px;
}


.page-title {
    font-size: 30px;
    text-transform: uppercase;
    margin-top: 60px;
}

.page-text {
    font-size: 17px;
    text-align: justify;
    padding-left: 10px;
    width: calc(100% - 35px)
}

.leaflet-popup-content {
    width: 200px !important;
}

.classPopup {
    width: 200px;
    height: 100%;
    max-height: 350px;
    font-size: 12px;
}



.classPopup .img {
    width: 100%;
}

.classPopup .img img {
    width: 100%;
}

.objects-list-container {
    z-index: 100;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}


.objects-list-container .list-img {
    width: 100%;
    height: var(--object-item-height);
    overflow: hidden;
}

.objects-list-container .list-title {
    font-family: Roboto, sans-serif;
    font-size: 13px !important;
    padding: 5px;
    text-align: center;
    background: var(--main);
    color: white;
    width: calc(100% - 10px);
    height: 35px;
    transition: background-color 0.3s;
}

.object-list-element.selected .list-title {
    background: var(--main-active);
}

.object-list-element.selected img {
    filter: brightness(80%);
}

.object-list-element:hover img {
    transform: scale(1.1);
    filter: brightness(80%);
}

.object-list-element {
    border-radius: 8px;
    overflow: hidden;

    margin-right: 14px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}


.objects-list-container .list-img img {
    width: 100%;
    transition: transform 0.3s;
}


.splide__arrow svg {
    fill: #ffffff !important;
}

.splide__arrow {
    background: var(--main) !important;
    width: 50px !important;
    height: 50px !important;
    opacity: 0.3 !important;
    border: 5px solid var(--main-active) !important;
    transition: 0.5s;
}

.splide:hover .splide__arrow {
    opacity: 0.7 !important;
}

.actionsUi {
    padding-bottom: 10px;
}

.actionsUi select {
    height: 50px;
    margin-right: 10px;
}

.box-elements {
    display: flex;
    flex-wrap: wrap;
    flex: border-box;
    justify-content: left;
    padding-top: 15px;
}



.box-element {
    background-color: var(--main);
    border-radius: 5px;
    overflow: hidden;
    transition: 300ms;
    cursor: pointer;
    text-decoration: none;
    margin-right: var(--box-space);
}

.box-element .img {
    width: 100%;
    height: 300px;
    overflow: hidden; /* Hides overflowing content */
    position: relative;
}
.box-element img {
    width: 100%; /* 100% of the parent container */
    height: 100%; /* 100% of the parent container */
    object-fit: cover; /* Makes sure the image covers the container while maintaining its aspect ratio */
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;
}

.bm3 .box-element {
    width: calc(100%/3 - var(--box-space)); /* 33.33% width for three items with 10px margin between them */
    margin-bottom: var(--box-space); /* Margin between rows */
}

.bm4 .box-element {
    width: calc(100%/4 - var(--box-space)); /* 33.33% width for three items with 10px margin between them */
    margin-bottom: var(--box-space); /* Margin between rows */
    background: #f1f1f1;
}

.box-element .title {
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    color: #ffffff;

    transition: 0.2s;
}

.bm4 .box-element .title {
    text-transform: none !important;
    line-height: 18px;
    background: #f1f1f1;
    color: #005a86;
    padding-top: 10px;
    padding-bottom: 10px;
}


.box-element:hover {
    transform: translateY(-10px);
}

.box-element:hover {
    background: var(--main-active);
    color: #ffffff;
}

.box-element:hover img {
    transform: scale(1.1);
    opacity: 0.7;
}

.divider {
    color: #b1b1b1;
    margin-left: 5px;
    margin-right: 5px;
}




@media screen and (max-width: 1150px) {
    .bm4 .box-element {
        width: calc(100%/3 - var(--box-space)) !important;
    }
}

@media screen and (max-width: 991px) {
    .bm4 .box-element, .bm3 .box-element {
        width: calc(100%/2 - var(--box-space)) !important;
    }
}

@media screen and (max-width: 600px) {
    .bm3 .box-element, .bm4 .box-element {
        width: calc(100%/1 - var(--box-space)) !important;
    }
    .object-list-info, .object-list-img, .object-list-img .splide, .list-img {
        width: auto;
    }
    .inner-containers {
        width: calc(100% + var(--box-space) - 20px);
    }
}

@media screen and (max-width: 480px) {
    .objects-map-container {
        height: calc(100vh - var(--object-item-height) - 200px) !important;
    }
}
