/* Hide the default scrollbar */
.scroll-container {
    position: relative;
    padding-right: 3px;
    overflow-y: scroll;
    height: 150px
}
.scroll-container::-webkit-scrollbar {
    width: 5px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: var(--main);
    border-radius: 10px;
    display: none;
}

/* Style the track (the area the thumb moves on) */
.scroll-container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
}

/* Handle hover effects for the thumb */
.scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: var(--main-active);
}

.scroll-container:hover::-webkit-scrollbar-thumb {
    display: block;
}
